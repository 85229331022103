import {hasImage} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {Image as Presentation} from './image'
import {ImageRuntimeProps} from './interfaces'

const mapRuntime = ({state}: DetailsPageAppProps): ImageRuntimeProps => {
  const {event} = state

  return {
    imageVisible: hasImage(event),
  }
}

export const Image = connect<{}, ImageRuntimeProps>(mapRuntime)(Presentation)

import {isDraft} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {RsvpButtonStyleOwnProps, RuntimeProps} from './interfaces'
import {RegistrationButtonSkin as Presentation} from './registration-button-skin'

const mapRuntime = ({state}: DetailsPageAppProps): RuntimeProps => ({
  draftEvent: state.event && isDraft(state.event),
})

export const RegistrationButtonSkin = connect<RsvpButtonStyleOwnProps, RuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'

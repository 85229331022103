import {getEventDescription} from '@wix/wix-events-commons-statics'
import {isSideBySideHeaderLayout} from '../../../../selectors/settings'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {Description as DescriptionPresentation} from './description'
import {DescriptionOwnProps, DescriptionRuntimeProps} from './interfaces'

const mapRuntime = ({state}: DetailsPageAppProps): DescriptionRuntimeProps => ({
  description: getEventDescription(state.event),
  isSideBySideHeaderLayout: isSideBySideHeaderLayout(state.component.settings),
})

export const Description = connect<DescriptionOwnProps, DescriptionRuntimeProps>(mapRuntime)(DescriptionPresentation)
export * from './interfaces'

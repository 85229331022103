import {isActive} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {usePageActions, usePageStateSelector} from '../../../hooks/state'
import {SectionButton} from '../section-button'
import s from './recurring-dates-button.scss'

interface RecurringDatesButtonProps {
  className?: string
  narrowerButton?: boolean
}

export const RecurringDatesButton = ({className, narrowerButton}: RecurringDatesButtonProps) => {
  const {t} = useTranslation()
  const eventId = usePageStateSelector(state => state).event.id
  const isActiveEvent = usePageStateSelector(state => isActive(state.event))
  const {openRecurringEventsModal} = usePageActions()

  return (
    <SectionButton
      onClick={() => openRecurringEventsModal(eventId)}
      className={classNames(s.root, className)}
      dataHook={DH.RECURRING_DATES_BUTTON}
      narrowerButton={narrowerButton}
    >
      {isActiveEvent ? t('recurring.selectDatesButton') : t('recurring.selectUpcomingDatesButton')}
    </SectionButton>
  )
}
